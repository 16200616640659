import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import RunningText from '../../../components/common/running-text/RunningText'
import Image from 'next/image'

interface Logo {
  name: string
  url: string
  rawUrl: string
}

interface Review {
  id: string
  Vietnamese: string
  Status: 'Active' | 'InActive' // Assuming status can only be "Active" or "InActive"
  Logo: Logo[]
  Title?: string // Assuming Title is optional
  Country: string
  'Updated time': string // Assuming "Updated time" is a string in format MM/YYYY
  Rating: string
  English: string
  Reviewer: string
  Source: string
}

export default function Testimonials() {
  const { t, i18n } = useTranslation('home')

  const [data, setData] = useState<Review[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const getData = async () => {
    try {
      if (isLoading) {
        return
      }
      setIsLoading(true)
      const res = await fetch('/api/v1/testimonials')
      const json = await res.json()
      setData(json.data)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const renderCard = (item: Review, index: number) => {
    const textDesc = i18n.language === 'vi' ? item.Vietnamese : item.English
    let cardWidth = 'lg:w-[30vw] w-[80vw]'
    if (textDesc.length > 150) {
      cardWidth = 'lg:w-[40vw] w-[80vw]'
    }
    if (textDesc.length > 250) {
      cardWidth = 'lg:w-[50vw] w-[80vw]'
    }

    return (
      <div
        className={`bg-block rounded-xl border border-solid p-8 mx-3 h-full ${cardWidth}`}
        style={{ borderColor: '#f5f5f5' }}
      >
        <div className="flex justify-between items-center flex-wrap mb-6">
          <div className="flex items-center">
            <div className="h-12 w-12 rounded-full overflow-hidden bg-white">
              <Image
                src={`/images/home/testimonials/avatars/ava-${(index + 1) % 12}.png`}
                alt={item.Reviewer}
                className="w-full h-auto"
                height={48}
                width={48}
              />
            </div>
            <p className="text-md text-black font-semibold mb-0 ml-5">{item.Reviewer}</p>
          </div>
          <Image
            className="h-7 w-auto"
            src={`/images/home/testimonials/${item.Source.replaceAll(' ', '')}.svg`}
            alt={item.Source}
            height={28}
            width={28}
          />
        </div>
        <p className="text-black text-display-xs font-semibold mb-3">{item.Title}</p>
        <p className="text-lg mb-0">{textDesc}</p>
      </div>
    )
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <section className="pb-12 overflow-hidden">
      <div className="section-container pt-20 pb-0">
        <h2 className="lg:text-display-xl sm:text-display-lg text-display-md text-black font-semibold mb-16">
          {t('testimonials.title')}
        </h2>
      </div>

      <RunningText pauseOnHover duration="60s">
        <div className="flex py-3">
          {data.slice(0, Math.floor(data.length / 2)).map((item, index) => (
            <div key={item.id}>{renderCard(item, index)}</div>
          ))}
        </div>
      </RunningText>

      <RunningText inverted pauseOnHover duration="60s">
        <div className="flex py-3">
          {data.slice(Math.floor(data.length / 2)).map((item, index) => (
            <div key={item.id}>{renderCard(item, index * 2)}</div>
          ))}
        </div>
      </RunningText>
    </section>
  )
}

import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import Image from 'next/image'

const TrustPilotRatings = dynamic(() => import('./components/TrustPilotRatings'), { ssr: false })

export default function Platforms() {
  const { t } = useTranslation('home')

  const platforms = [
    {
      name: 'apple',
      url: '/passwords/download#macos',
    },
    {
      name: 'linux',
      url: '/passwords/download#linux',
    },
    {
      name: 'windows',
      url: '/passwords/download#windows',
    },
    {
      name: 'appstore',
      url: 'https://apps.apple.com/us/app/locker-password-manager/id1586927301',
    },
    {
      name: 'playstore',
      url: 'https://play.google.com/store/apps/details?id=com.cystack.locker&pli=1',
    },
    {
      name: 'firefox',
      url: 'https://addons.mozilla.org/en-US/firefox/addon/locker-password-manager/',
    },
    {
      name: 'chrome',
      url: 'https://chrome.google.com/webstore/detail/locker-password-manager/cmajindocfndlkpkjnmjpjoilibjgmgh',
    },
    {
      name: 'brave',
      url: 'https://chromewebstore.google.com/detail/locker-password-manager/cmajindocfndlkpkjnmjpjoilibjgmgh',
    },
  ]

  const ratings = [
    {
      name: 'GetApp',
      url: 'https://www.getapp.com/security-software/a/locker-password-manager/',
    },
    {
      name: 'G2',
      url: 'https://www.g2.com/products/locker-password-manager/reviews?utm_source=Iterable&utm_medium=email&utm_campaign=it_txn_claimedprod_rej_1403101',
    },
    {
      name: 'GooglePlay',
      url: 'https://play.google.com/store/apps/details?id=com.cystack.locker&pli=1',
    },
    {
      name: 'AppStoreRating',
      url: 'https://apps.apple.com/us/app/locker-password-manager/id1586927301',
    },
  ]

  return (
    <>
      <section>
        {/* Platforms */}
        <div className="bg-block py-12">
          <div className="section-container flex flex-wrap items-center">
            <div className="lg:w-3/12 w-full">
              <h2 className="lg:text-left text-center lg:mb-0 mb-8 text-xl font-semibold text-secondary-45">
                {t('platforms.title_1')}
              </h2>
            </div>
            <div className="w-1/12"></div>
            <div className="lg:w-8/12 w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-8 gap-9">
              {platforms.map((item) => (
                <Link target="_blank" key={item.name} href={item.url}>
                  <div className="relative flex justify-center">
                    <Image
                      className="h-20 w-auto relative z-0"
                      src={`/images/home/platforms/${item.name}-gray.svg`}
                      alt={item.name}
                      height={80}
                      width={80}
                    />
                    <Image
                      className="h-20 w-auto absolute top-0 z-10 opacity-0 hover:opacity-100 transition-opacity"
                      src={`/images/home/platforms/${item.name}.svg`}
                      alt={item.name}
                      height={80}
                      width={80}
                    />
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
        {/* Platforms end */}

        {/* Ratings */}
        <div className="section-container py-12">
          <div className="xl:w-10/12 mx-auto w-full">
            <h2 className="text-center text-xl font-semibold mb-6">{t('platforms.title_2')}</h2>

            <div className="flex flex-wrap items-center justify-center">
              <TrustPilotRatings />

              {/* Others */}
              {ratings.map((item) => (
                <div key={item.name} style={{ width: 160 }} className="mb-4">
                  <Link
                    href={item.url}
                    target="_blank"
                    rel="noopener"
                    className="flex justify-center"
                  >
                    <Image
                      src={`/images/home/platforms/${item.name}.svg`}
                      alt={item.name}
                      style={{ height: 50, width: 'auto' }}
                      height={50}
                      width={50}
                    />
                  </Link>
                </div>
              ))}
              {/* Others end */}
            </div>
          </div>
        </div>
        {/* Ratings end */}
      </section>
    </>
  )
}

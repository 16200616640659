import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import ENDPOINT from '@/config/endpoint'
import Image from 'next/image'

export default function StartForFree() {
  const { t } = useTranslation('home')

  return (
    <>
      <section className="section-container py-20">
        <div className="flex flex-wrap items-center rounded-3xl py-16 sm:px-16 px-4 cta-bg">
          <div className="w-1/12"></div>
          <div className="lg:w-5/12 w-full mb-8 lg:mb-0 flex flex-col items-center lg:items-start">
            <h2 className="text-display-md font-semibold text-primary mb-8 text-center lg:text-left">
              {t('cta.title')}
            </h2>
            <Link target="_blank" href={`${ENDPOINT.ID_GENERAL_REGISTER}`}>
              <div className="primary-btn-outline">{t('cta.btn')}</div>
            </Link>
          </div>
          <div className="w-2/12"></div>
          <div className="lg:w-3/12 w-full">
            <Image
              className="w-full"
              src={`/images/home/cta/cta.svg`}
              style={{ maxHeight: 308 }}
              alt="CTA"
              height={308}
              width={370}
            />
          </div>
          <div className="w-1/12"></div>
        </div>
      </section>
    </>
  )
}

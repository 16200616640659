import { useTranslation } from 'next-i18next'
import { ArrowRightOutlined, UpOutlined, DownOutlined } from '@ant-design/icons'
import { useState, useEffect, useRef, useMemo } from 'react'
import Slider from 'react-slick'
import { Tooltip } from '@lockerpm/design'
import { NewsItem } from 'src/pages/api/v1/news'
import Link from 'next/link'

type Props = {
  tags?: NewsItem['Tag']
}

export default function LatestNews({ tags }: Props) {
  const { i18n } = useTranslation('nav')

  const [news, setNews] = useState<NewsItem[]>([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const sliderRef = useRef(null)

  const loadNews = async () => {
    try {
      const res = await fetch('/api/v1/news')
      const json = await res.json()
      setNews(json.data)
    } catch (error) {
      console.error(error)
    }
  }

  const visibleNews = useMemo(() => {
    return news.filter((data: NewsItem) => {
      if (data?.Status !== 'Active') {
        return false
      }
      if (!data.Tag?.some((t) => tags.includes(t))) {
        return false
      }
      return (
        ((i18n.language === 'vi' && data?.Language === 'Vietnamese') ||
          (i18n.language === 'en' && data?.Language === 'English')) &&
        data?.Link
      )
    })
  }, [news, tags, i18n.language])

  useEffect(() => {
    loadNews()
  }, [])

  return visibleNews.length ? (
    <>
      <div className="bg-block rounded-full w-full py-3 px-6 flex items-center">
        {visibleNews.length > 1 && (
          <div className="mr-2 flex flex-col">
            <UpOutlined
              onClick={() => {
                sliderRef.current.slickNext()
              }}
              className="text-white-secondary hover:text-primary transition-colors"
            />
            <DownOutlined
              onClick={() => {
                sliderRef.current.slickPrev()
              }}
              className="text-white-secondary hover:text-primary transition-colors"
            />
          </div>
        )}
        <div className="flex-1 overflow-hidden">
          <Slider
            ref={sliderRef}
            dots={false}
            arrows={false}
            infinite
            vertical
            pauseOnHover
            autoplay
            autoplaySpeed={4000}
            slidesToShow={1}
            slidesToScroll={1}
            afterChange={setCurrentIndex}
          >
            {visibleNews.map((item) => (
              <Link key={item.id} href={item.Link.trim()} target="_blank">
                <Tooltip title={item.Text}>
                  <p className="text-black mx-3 line-clamp-1 mb-0">{item.Text}</p>
                </Tooltip>
              </Link>
            ))}
          </Slider>
        </div>
        <Link
          href={visibleNews[Math.min(currentIndex, visibleNews.length - 1)]?.Link}
          target="_blank"
        >
          <ArrowRightOutlined className="text-primary" />
        </Link>
      </div>
    </>
  ) : null
}
